@use "../commonStyles/themes_1" as globalStyles;
.s3-header {
  display: flex;
  justify-content: space-between;
  padding: 1% 1% 1% 0%;
}

.s3-subheader {
  padding: 1% 0% 1% 0%;
}
.s3-bg-color {
  height: 18px;
  width: 18px;
}

.bgProps {
  background-color: globalStyles.$clr-background;
}

.column-style {
  padding-left: 2%;
}

.file-input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}

.custom-file-input {
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.custom-file-input:hover {
  background-color: #e0e0e0;
}

.selected-file-details {
  margin-top: 20px;
  text-align: center;
}

.custom-file-input input[type="file"] {
  display: none;
}

.clickable-folder-yes {
  cursor: pointer;
  color: blue;
  display: flex;
}

.clickable-folder-no {
  display: flex;
}
.breadcrumb-item {
  cursor: pointer;
}
