@use "../../commonStyles/themes_1" as globalStyles;

/* The switch - the box around the slider */
.tree-node-content {
  display: flex; /* Make the children appear in a row */
  align-items: center; /* Vertically align the children */
}

.switch {
  margin-left: 20px;
  font-size: 17px;
  position: relative;
  display: inline-block;
  width: 1.5em;
  height: 1em;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  inset: 0;
  background: #9fccfa;
  border-radius: 50px;
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.slider:before {
  position: absolute;
  content: "";
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1em;
  width: 1em;
  inset: 0;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.4);
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.switch input:checked + .slider {
  background: #0974f1;
}

.switch input:focus + .slider {
  box-shadow: 0 0 1px #0974f1;
}

.switch input:checked + .slider:before {
  transform: translateX(0.8em);
}
.cursorPointer {
  cursor: pointer;
}
.checkbox-title {
  margin-left: 10px;
}
