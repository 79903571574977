button {
  background-color: unset;
  border: unset;
}
button:hover {
  color: rgb(26, 26, 26);
}

.node-container {
  position: relative;
  margin-left: 30px;
  padding: 10px 0;
  border-left: 1px solid rgb(174, 172, 172);
}
.node-container.root-node {
  border-left: none;
}
.node-container:first-child {
  border-left: 0;
}

.node-header {
  display: flex;
  align-items: center;
}
.menu-name {
  padding-left: 18px;
  padding-top: 15px;
  padding-bottom: 10px;
}

.add-root-node-button {
  padding-left: 30px;
  margin-top: 5px;
}

.horizontal-line {
  border-bottom: 1px solid rgb(164, 162, 162);
  width: 8px;
}

.tree-line {
  position: absolute;
  left: -18px;
  top: 50%;
  transform: translateY(-50%);
  border-left: 1px solid #ccc;
  height: 100%;
}

.minimize-icon {
  cursor: pointer;
  font-size: 24px;
  padding-bottom: 0px;
  color: gray;
}

.node-title {
  font-weight: bold;
  margin-right: 15px;
}

.edit-icon {
  cursor: pointer;
  margin-right: 3px;
  margin-top: 0px;
}

.trash-icon {
  cursor: pointer;
  margin-top: 0px;
  margin-left: -10px;
}
.add-icon {
  cursor: pointer;
  margin-left: 10px;
  margin-top: 15px;
}

.node-container {
  /* existing styles for the node container */
  position: relative;
}

.last-child-button {
  position: absolute;
  left: 0;
  top: 0;
}

.node-content {
  position: relative;
  border-left: 1px solid gray;
  margin-left: 50px;
  margin-bottom: 0px;
}
#dashboard {
  height: 40px;
}

#sheet {
  height: 13px;
}

.horizontal-line1 {
  border-bottom: 1px solid rgb(164, 162, 162);
  width: 8px;
  margin-top: -12px;
}
.minimize-icon1 {
  margin-top: 0px;
}
.circle-icon {
  margin-top: 5px;
  height: 10px;
}
.node-container {
  margin-top: 10px;
  width: max-content;
}

.horizontal-line2 {
  border-bottom: 1px solid rgb(164, 162, 162);
  width: 8px;
  margin-top: -15px;
  margin-left: 25px;
}
.horizontal-line3 {
  border-bottom: 1px solid rgb(164, 162, 162);
  width: 8px;
  margin-top: -15px;
  margin-left: 0px;
}
.add-terminal-node-button {
  padding-left: 7px;
  margin-top: 15px;
}

#sheet {
  height: 42px;
}
