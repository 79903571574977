.nav-wrapper {
  flex-direction: column;
  overflow-x: hidden;
}

.img-fix {
  background-image: url("../../assets/home1.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  overflow: scroll;
}
