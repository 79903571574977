@use "../commonStyles/styles" as globalStyles;
@use "../commonStyles/fonts" as globalFont;

.logo {
  height: 35px;
  margin-right: 4rem;
  padding: 5% 0% 5% 0%;
}

.logout-icon {
  cursor: pointer;
}
